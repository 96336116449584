document.addEventListener('DOMContentLoaded', function () {
    // Parent element for event delegation
    const reservationGroupTable = document.getElementById('reservation-group-table');
    const invoiceGroupTable = document.getElementById('invoice-group-table');

    if (reservationGroupTable) {
      // Handle select button clicks
      reservationGroupTable.addEventListener('click', (event) => {
        const clickedElement = event.target;
        const wrapper = clickedElement.closest('.wrapper');
    
        if (wrapper && wrapper.querySelector('.select-btn') === clickedElement) {
          wrapper.classList.toggle("active");
        }
      });
    
      // Handle accordion header clicks
      reservationGroupTable.addEventListener('click', (event) => {
        const clickedElement = event.target;
        const accordionHeader = clickedElement.closest('.accordion-header');
    
        if (accordionHeader) {
          const accordionBody = accordionHeader.nextElementSibling;
          if (accordionBody && accordionBody.classList.contains('accordion-body')) {
            const tableId = accordionBody.id
            const filters = JSON.parse(accordionBody.getAttribute('data-filters'));
            $.ajax({
              url: "/load_booking_reservations",
              type: 'GET',
              data: {status: tableId, filters: filters},
              success: function(data) {
                
              },
              complete: function(data) {
                const isActive = accordionHeader.classList.toggle('active');
                accordionBody.style.display = isActive ? 'block' : 'none';
              }
            });
          }
        }
      });
    
      // Handle accordion header table clicks within each accordion
      reservationGroupTable.addEventListener('click', (event) => {
        const clickedElement = event.target;
        const accordionArrow = clickedElement.closest('.accordion-arrow');
    
        if (accordionArrow) {
          event.stopPropagation(); // Prevent bubbling
    
          const headerTable = accordionArrow.closest('.accordion-header-table');
          const booking_id = accordionArrow.id
          if (headerTable) {
              const accordionTableBody = headerTable.nextElementSibling;
    
              if (accordionTableBody && accordionTableBody.classList.contains('accordion-body-table')) {
                $.ajax({
                  url: "/load_child_reservations",
                  type: 'GET',
                  data: {booking_id: booking_id},
                  success: function(data) {
                  },
                  complete: function(data) {
                    const isVisible = accordionTableBody.style.display === 'table-row' || accordionTableBody.style.display === '';
                    accordionTableBody.style.display = isVisible ? 'none' : 'table-row';
                    accordionArrow.classList.toggle('rotated', !isVisible);
                  }
                });
              }
          }        
        }
      });
    }

    if (invoiceGroupTable) {
      // Handle select button clicks
      invoiceGroupTable.addEventListener('click', (event) => {
        const clickedElement = event.target;
        const wrapper = clickedElement.closest('.wrapper');
    
        if (wrapper && wrapper.querySelector('.select-btn') === clickedElement) {
          wrapper.classList.toggle("active");
        }
      });
    
      // Handle accordion header clicks
      invoiceGroupTable.addEventListener('click', (event) => {
        const clickedElement = event.target;
        const accordionHeader = clickedElement.closest('.accordion-header');
    
        if (accordionHeader) {
          const accordionBody = accordionHeader.nextElementSibling;
          if (accordionBody && accordionBody.classList.contains('accordion-body')) {
            const isActive = accordionHeader.classList.toggle('active');
            accordionBody.style.display = isActive ? 'block' : 'none';
          }
        }
      });
    
      // Handle accordion header table clicks within each accordion
      invoiceGroupTable.addEventListener('click', (event) => {
        const clickedElement = event.target;
        const accordionArrow = clickedElement.closest('.accordion-arrow');
    
        if (accordionArrow) {
          event.stopPropagation(); // Prevent bubbling
    
          const headerTable = accordionArrow.closest('.accordion-header-table');

          if (headerTable) {
              const accordionTableBody = headerTable.nextElementSibling;
    
              if (accordionTableBody && accordionTableBody.classList.contains('accordion-body-table')) {
              const isVisible = accordionTableBody.style.display === 'table-row' || accordionTableBody.style.display === '';
              accordionTableBody.style.display = isVisible ? 'none' : 'table-row';
              accordionArrow.classList.toggle('rotated', !isVisible);
              }
          }        
        }
      });
    }
  });